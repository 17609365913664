.App {
  text-align: center;
}

.App-header {
  background-color: #fff;
  display: flex;
  font-size: calc(10px + 2vmin);
  color: rgb(32, 36, 117);
  padding: 1rem;
}

.App-link {
  color: #61dafb;
}


.headings {
  display: flex;
  padding: 1.125rem 1.5625rem 2rem 0;
  border-bottom: 1.5px solid lightgray;

  .logo-container {
      display: flex;
      align-items: flex-start;
      justify-content: start;
      margin-top: .875rem;
      width: 100%;
      .honeywell-title {
        height: 1.78rem;
        color: #e71e24;
        margin-bottom: -.375rem;
        padding-left: 0.5rem;
      }
  }

  .vertical-divider {
      height: 22.36px;
      width: 1px;
      background-color: #747474;
      margin: 0 1rem;
    }
           
    .smartview-text {
      height: 1.375rem;
      opacity: 0.85;
      color: #303030;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.375rem;
      padding-top: 0.25rem;
    }
}